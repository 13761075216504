import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { nextTick } from "vue";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/myinfo",
        name: "myinfo",
        component: () => import("@/views/auth/MyInformation.vue"),
      },
      {
        path: "/menu/companies",
        name: "companies",
        component: () => import("@/views/check/Check.vue"),
        children: [
          {
            path: "my-company",
            name: "my-company",
            component: () => import("@/views/companies/Companies.vue"),
          },
          {
            path: "logo",
            name: "companie-logo",
            component: () => import("@/views/companies/Logo.vue"),
          },
        ],
      },

      {
        path: "/businesses",
        name: "businesses",
        component: () => import("@/views/businesses/Businesses.vue"),
      },
      {
        path: "/brands",
        name: "brands",
        component: () => import("@/views/brands/Brands.vue"),
      },
      {
        path: "/stores",
        name: "stores",
        component: () => import("@/views/stores/Stores.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/users/Users.vue"),
      },
      {
        path: "/schedules",
        name: "schedules",
        component: () => import("@/views/schedules/Schedules.vue"),
      },
      {
        path: "/schedule-add",
        name: "schedule-add",
        component: () => import("@/views/schedules/NewSchedule.vue"),
      },
      {
        path: "/schedule-detail",
        name: "schedule-detail",
        props: true,
        component: () => import("@/views/schedules/DetailSchedule.vue"),
      },
      // {
      //   path: "/qsc",
      //   name: "qsc",
      //   component: () => import("@/views/qsc/QscCheckList.vue"),
      // },
      {
        path: "/menu/qsc",
        name: "qsc",
        component: () => import("@/views/check/Check.vue"),
        children: [
          {
            path: "qsc-sv",
            name: "qsc-sv",
            component: () => import("@/views/qsc/QscCheckList.vue"),
          },
          {
            path: "qsc-store",
            name: "qsc-store",
            component: () => import("@/views/qsc/QscCheckListStore.vue"),
          },
        ],
      },
      {
        path: "/qsc-check",
        name: "qsc-check",
        props: true,
        component: () => import("@/views/qsc/Qsc.vue"),
      },
      {
        path: "/check-results",
        name: "check-results",
        props: true,
        component: () => import("@/views/results/DetailVersionResults.vue"),
      },
      {
        path: "/menu/results",
        name: "results",
        component: () => import("@/views/check/Check.vue"),
        children: [
          {
            path: "item-detail",
            name: "item-detail",
            component: () => import("@/views/results/Results.vue"),
          },
          {
            path: "version-detail",
            name: "version-detail",
            component: () => import("@/views/results/VersionResults.vue"),
          },
          {
            path: "version-detail-store",
            name: "version-detail-store",
            component: () => import("@/views/results/VersionResultsStore.vue"),
          },
        ],
      },
      {
        path: "/menu/check-items",
        name: "check-items",
        component: () => import("@/views/check/Check.vue"),
        children: [
          {
            path: "items",
            name: "check-items-items",
            component: () => import("@/views/check/CheckItems.vue"),
          },
          {
            path: "versions",
            name: "check-items-versios",
            component: () => import("@/views/check/CheckItemVersions.vue"),
          },
          {
            path: "versions-detail",
            name: "check-items-versions-detail",
            component: () => import("@/views/check/DetailCheckItemVersions.vue"),
            props: true,
          },
          {
            path: "item-add",
            name: "check-items-add",
            component: () => import("@/views/check/NewCheckItem.vue"),
          },
          {
            path: "item-detail",
            name: "check-items-detail",
            component: () => import("@/views/check/DetailCheckItem.vue"),
            props: true,
          },
          {
            path: "version-add",
            name: "check-version-add",
            component: () => import("@/views/check/NewCheckItemVersion.vue"),
          },
          {
            path: "category",
            name: "check-category",
            component: () => import("@/views/check/Category.vue"),
          },
          {
            path: "category-add",
            name: "check-category-add",
            component: () => import("@/views/check/NewCategory.vue"),
          },
        ],
      },
    ],
  },

  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
  },

  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedin = localStorage.getItem("access_token");
  if (loggedin) {
    if (to.path === "/sign-in") {
      next("/dashboard");
    } else {
      next();
    }
  } else {
    if (to.path !== "/sign-in") {
      next("/sign-in");
    }
    next();
  }
});

export default router;
