import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import i18n from "@/core/plugins/i18n";

import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import ElementPlus from "element-plus";
import ko from "element-plus/es/locale/lang/ko";

import VueCookies from "vue3-cookies";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, {
  locale: ko,
});
app.use(VueCookies, {
  expireTimes: "10d",
  secure: true,
});

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
