import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import axios from "axios";
import router from "@/router/index";

const _axios = axios.create();

@Module
export default class Auth extends VuexModule {
    token = "";
    id = "";
    userTy = "";

    get isUserId(): string {
        return this.id;
    }

    @Mutation
    setToken(userData) {
        this.token = userData.token;
        this.id = userData.user.id;
        this.userTy = userData.user.user_ty;
        localStorage.setItem("access_token", this.token);
        localStorage.setItem("userId", this.id);
        localStorage.setItem("userTy", this.userTy);
        location.reload(); //vuex, axiod header clear
    }

    @Mutation
    removeToken() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("userId");
        localStorage.removeItem("userTy");
    }

    @Action
    login(authData) {
        console.log(authData);
        _axios
            .post("/auth/login", authData)
            .then((res) => {
                console.log(res);
                if (res.status === 201) {
                    this.context.commit("setToken", res.data);
                    router.push({ name: "dashboard" });
                }
            })
            .catch((error) => {
                console.log(error.response);
                alert("아이디 혹은 비밀번호를 확인해주세요.");
            });
    }

    @Action
    logout() {
        const token = localStorage.getItem("access_token");

        const config = {
            headers: { Authorization: "token " + token },
        };
        console.log("config", config);
        _axios
            .post("/auth/logout", null, config)
            .then((res) => {
                console.log(res);
                if (res.status === 204) {
                    this.context.commit("removeToken");
                    router.push({ name: "sign-in" });
                }
            })
            .catch((error) => {
                console.log(error.response);
                this.context.commit("removeToken");
                router.push({ name: "sign-in" });
            });
    }
}
